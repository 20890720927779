// import moment from 'moment'

// 获取当前日期时间 xxxx-xx-xx xx:xx:xx
function getNowDateTime() {
  const nowDate = new Date();
  let time = nowDate.getFullYear() + '-' +
    splicing(nowDate.getMonth() + 1) + '-' +
    splicing(nowDate.getDate()) + ' ' +
    splicing(nowDate.getHours()) + ':' +
    splicing(nowDate.getMinutes()) + ':' +
    splicing(nowDate.getSeconds());
  return time;
}

// 获取当前日期 xxx-xx-xx
function getNowDate() {
  const nowDate = new Date();
  let time = nowDate.getFullYear() + '-' +
    splicing(nowDate.getMonth() + 1) + '-' +
    splicing(nowDate.getDate());
  return time;
}

// 获取当前时间 xx:xx:xx
function getNowTime() {
  const nowDate = new Date();
  let time = splicing(nowDate.getHours()) + ':' +
    splicing(nowDate.getMinutes()) + ':' +
    splicing(nowDate.getSeconds());
  return time;
}
// 时间格式化 xx:xx:xx
function getValueTime(value) {
  const nowDate = new Date(value);
  let time = splicing(nowDate.getHours()) + ':' +
    splicing(nowDate.getMinutes()) + ':' +
    splicing(nowDate.getSeconds());
  return time;
}

// 获取上一周日期时间 xxxx-xx-xx xx:xx:xx
function getPrevWeek() {
  const prevWeek = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
  let time = prevWeek.getFullYear() + '-' +
    splicing(prevWeek.getMonth() + 1) + '-' +
    splicing(prevWeek.getDate()) + ' ' +
    splicing(prevWeek.getHours()) + ':' +
    splicing(prevWeek.getMinutes()) + ':' +
    splicing(prevWeek.getSeconds());
  return time;
}

// 获取上一周日期 xxxx-xx-xx
function getPrevWeekDate() {
  const prevWeek = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
  let time = prevWeek.getFullYear() + '-' +
    splicing(prevWeek.getMonth() + 1) + '-' +
    splicing(prevWeek.getDate());
  return time;
}

// 获取某天凌晨-下一天凌晨
function getDayDate(date) {
  let newDate = new Date(date);
  let dateTime = newDate.getFullYear() + '-' +
    splicing(newDate.getMonth() + 1) + '-' +
    splicing(newDate.getDate()) + ' 00:00:00';

  let time = new Date(newDate).getTime() + 1 * 24 * 60 * 60 * 1000;
  let nextDate = new Date(time);
  let nextDateTime = nextDate.getFullYear() + '-' +
    splicing(nextDate.getMonth() + 1) + '-' +
    splicing(nextDate.getDate()) + ' ' + '00:00:00';
  return [dateTime, nextDateTime];
}
// 获取某天凌晨-当天23：59：59
function getCurDayDate(date) {
  let newDate = new Date(date);
  let dateTime = newDate.getFullYear() + '-' +
    splicing(newDate.getMonth() + 1) + '-' +
    splicing(newDate.getDate()) + ' 00:00:00';

  let time = new Date(newDate).getTime();
  let nextDate = new Date(time);
  let nextDateTime = nextDate.getFullYear() + '-' +
    splicing(nextDate.getMonth() + 1) + '-' +
    splicing(nextDate.getDate()) + ' ' + '23:59:59';
  return [dateTime, nextDateTime];
}

// 获取 当天时间-以前某一天凌晨
function getSomeDay(day = 0, time = true) {
  let today = new Date();
  let todayTime = today.getFullYear() + '-' +
    splicing(today.getMonth() + 1) + '-' +
    splicing(today.getDate());

  let someTime = new Date(today).getTime() - day * 24 * 60 * 60 * 1000;
  let someDay = new Date(someTime);
  let someDayTime = someDay.getFullYear() + '-' +
    splicing(someDay.getMonth() + 1) + '-' +
    splicing(someDay.getDate());
  if (time) {
    todayTime = todayTime + ' ' +
      splicing(today.getHours()) + ':' +
      splicing(today.getMinutes()) + ':' +
      splicing(today.getSeconds());

    someDayTime = someDayTime + ' 00:00:00';
  }
  return [someDayTime, todayTime];
}

// 获取 某天最后时刻 xxxx-xx-xx 23:59:59
function getDaySoonEnd(date) {
  let newDate = new Date(date);
  let dateTime = newDate.getFullYear() + '-' +
    splicing(newDate.getMonth() + 1) + '-' +
    splicing(newDate.getDate()) + ' 23:59:59';
  return dateTime;
}

// function getSomeDayByMoment(day = 0) {
//   return [moment().subtract(day, 'days').unix(), moment().unix()]
// }

// 转换格式 获取 年-月-日 时:分:秒/ bool = true 获取 年-月-日
function reformatting(date, bool = false) {
  let newDate = new Date(date);
  let time = newDate.getFullYear() + '-' +
    splicing(newDate.getMonth() + 1) + '-' +
    splicing(newDate.getDate());
  if (bool) {
    return time;
  } else {
    time = time + ' ' +
      splicing(newDate.getHours()) + ':' +
      splicing(newDate.getMinutes()) + ':' +
      splicing(newDate.getSeconds());
  }
  return time;
}

// 转换格式 获取 月-日 时:分
function shortDatetime(date) {
  let newDate = new Date(date);
  let time =
    splicing(newDate.getMonth() + 1) + '-' +
    splicing(newDate.getDate()) + ' ' +
    splicing(newDate.getHours()) + ':' +
    splicing(newDate.getMinutes());
  return time;
}

// 小于 10 的，前面拼接 0
function splicing(num) {
  if (typeof num !== 'number') {
    throw new Error('Splicing incoming values must be number type!');
  }
  let str = num + '';
  str = str[1] ? str : '0' + str;
  return str;
}

export {
  getNowDateTime,
  getNowDate,
  getNowTime,
  getPrevWeek,
  getPrevWeekDate,
  getDayDate,
  getCurDayDate,
  getSomeDay,
  reformatting,
  shortDatetime,
  splicing,
  getValueTime,
  // getSomeDayByMoment,
  getDaySoonEnd
}