
import qs from 'qs';
import store from '@/store';
/**
 * 生成基础axios对象，并对请求和响应做处理
 * 前后端约定接口返回解构规范
 * {
 *    code:0,
 *    data:"成功",
 *    message:""
 * }
 */
import axios from 'axios';
import { Message } from 'element-ui';

// 创建一个独立的axios实例
const service = axios.create({
  // 设置baseUrl地址,如果通过proxy跨域可直接填写base地址
  // 定义统一的请求头部
  headers: {
    // Form Data 格式
    // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    // Token: localStorage.getItem('Token'),
    Language: localStorage.getItem('language') || 'en-US',
  },
  // 配置请求超时时间
  timeout: 300000,
  // 如果用的JSONP，可以配置此参数带上cookie凭证，如果是代理和CORS不用设置
  // withCredentials: true,
});
// 请求拦截
service.interceptors.request.use((config) => {
  // console.log('请求拦截: ', config);
  if (localStorage.getItem('Token')) {
    config.headers.Token = localStorage.getItem('Token');
  }
  // 只针对get方式进行序列化
  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    };
  }
  return config;
});

// 返回拦截
service.interceptors.response.use((response) => {
  // 获取接口返回结果
  const res = response.data;
  if (res.code === 200) {
    return res;
  }
  if ([98000].includes(+res.code)) {
    // 也可使用router进行跳转
    window.location.href = '/#/serviceSuspension';
    return res;
  }
  if ([10000, 10005, 10021].includes(+res.code)) {
    // 10000假设是未登录状态码

    store.commit('setAccount', null);
    store.commit('setisWallet', false);
    store.commit('setToken', '');
    sessionStorage.removeItem('nisaConnect');
    localStorage.removeItem('clientCode');
    localStorage.removeItem('signature');
    localStorage.removeItem('walletconnect');

    if (!localStorage.getItem('Token')) {
      // 也可使用router进行跳转
      window.location.href = '/#/';
      return res;
    } else {
      localStorage.removeItem('Token')
      // 也可使用router进行跳转
      window.location.href = '/#/';
      Message.error(res.msg);
      return res;
    }
  } else {
    if ([10033, 10042, 10034, 10046].includes(res.code)) {
      return res;
    }
    Message.error(res.msg);
    return res;
  }
}, () => {
  // Message.error('ネットワーク要求例外です。しばらくしてからもう一度お試しください。');
});
export default service;
