<template>
  <div class="ueex-about-page">
    <div class="about-content-one">
      <dv class="content">
        <div class="title">
          {{ translateTitle("About Us") }}
        </div>
      </dv>
    </div>
    <div class="about-content-two">
      <dv class="content">
        <div class="title">
          {{ translateTitle("UEEx Digital Asset Trading Platform") }}
        </div>
        <main>
          <div class="left">
            <div class="item">
              {{ translateTitle("Founded in Dec 2020, UEEx is a trusted choice for those seeking a versatile and reliable cryptocurrency trading experience. Our mission is to provide users with a secure, feature-rich, and reliable environment to explore and engage with the crypto ecosystem.")  }}
            </div>
            <div class="item">
              {{ translateTitle("UEEx is built on values highly concentrated on user privacy and security.The most secure, with an address obfuscation strategy, and unlimited mass addresses.")  }}
            </div>
          </div>
          <div class="right">
            <img src="../../assets/img/ueex/about-us/logo-text.png" alt="">
          </div>
        </main>
      </dv>
    </div>
    
    <div class="about-content-three">
      <div class="content">
        <div class="box">
          <div class="title">
            {{ translateTitle("Our Services")   }}
          </div>
          <div class="item-box">
            <div v-for="item in ourServicesItem" class="item" :key="item.title">
              <div class="img">
                <img :src="item.url" alt="" />
              </div>
              <div class="item-title">
                {{ translateTitle(item.title) }}
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="title">
            {{ translateTitle("Useful tools")   }}
          </div>
          <div class="item-box">
            <div v-for="item in usefulToolsItem" class="item" :key="item.title">
              <div class="img">
                <img :src="item.url" alt="" />
              </div>
              <div class="item-title">
                {{ translateTitle(item.title) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-content-four">
      <div class="content">
        <div class="title">
          {{ translateTitle("UEEx Team") }}
        </div>
        <main>
          <div class="left">
            {{ translateTitle("UEEx boasts an elite team drawn from prestigious institutions such as Stanford, Peking University, and Fudan University. With 10% of UEEx team members  holding Ph.D.s and over 60% with master’s degrees, our team is exceptionally qualified. Prior to joining UEEx, these professionals honed their expertise at top-tier international financial institutions, private equity firms, and in cutting-edge technology and computer science, bringing a wealth of experience and insight to our operations.") }}
          </div>
          <div class="right">
            <img src="../../assets/img/ueex/about-us/team1.png" alt="">
            <img src="../../assets/img/ueex/about-us/team2.png" alt="">
            <img src="../../assets/img/ueex/about-us/team3.png" alt="">
            <img src="../../assets/img/ueex/about-us/team4.png" alt="">
          </div>
        </main>
      </div>
    </div>
    <div class="about-content-five">
      <div class="content">
        <div class="title-box">
          <div class="title">
            {{ translateTitle("Compliance & Security") }}
          </div>
        </div>
        <main>
          <div class="right">
            <img src="../../assets/img/ueex/about-us/security1.png" alt="">
            <img src="../../assets/img/ueex/about-us/security2.png" alt="">
          </div>
          <div class="left">
            <div class="item">
              <div>
                <img src="../../assets/img/ueex/about-us/compliance1.svg" alt="">
                {{ translateTitle("Regulatory Compliance:") }}
              </div>
              <div>
                {{ translateTitle("Collaborate with regulators to adhere to evolving industry standards, acquired the US MSB license and Canada MSC license.") }}
              </div>
            </div>
            <div class="item">
              <div>
                <img src="../../assets/img/ueex/about-us/compliance2.svg" alt="">
                {{ translateTitle("ML/CFT Measures:") }}
              </div>
              <div>
                {{ translateTitle("Implement robust anti-money laundering and counter-terrorism financing measures.") }}
              </div>
            </div>
            <div class="item">
              <div>
                <img src="../../assets/img/ueex/about-us/compliance3.svg" alt="">
                {{ translateTitle("Transparent:") }}
              </div>
              <div>
                {{ translateTitle("Funds management is transparent and open, with regular publication of proof-of-reserves reports.") }}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div class="about-content-six">
      <div class="content">
        <div class="title">
          {{ translateTitle("UEEx Milestones") }}
        </div>
        <main>
          <div class="top">
            <div v-for="item in milestonesTopItem" class="item" :key="item.text">
              <div class="item-text-box">
                <div class="item-title">
                  {{ translateTitle(item.title)  }}
                </div>
                <div class="item-text">
                  {{ translateTitle(item.text)  }}
                </div>
              </div>
              <div class="item-line">
              </div>
              <img src="../../assets/img/ueex/about-us/six-round.svg" alt="">
            </div>
          </div>
          <div class="line">
            <img src="../../assets/img/ueex/about-us/milestones-line.png" alt="">
          </div>
          <div class="btm">
            <div v-for="item in milestonesBtmItem" class="item" :key="item.text">
              <img src="../../assets/img/ueex/about-us/six-round.svg" alt="">
              <div class="item-line">
              </div>
              <div class="item-text-box">
                <div class="item-title">
                  {{ translateTitle(item.title)  }}
                </div>
                <div class="item-text">
                  {{ translateTitle(item.text)  }}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import commonMixin from "@/components/common/commonMixin.vue";
import our1 from "@/assets/img/ueex/about-us/our1.svg"
import our2 from "@/assets/img/ueex/about-us/our2.svg"
import our3 from "@/assets/img/ueex/about-us/our3.svg"
import our4 from "@/assets/img/ueex/about-us/our4.svg"
import our5 from "@/assets/img/ueex/about-us/our5.svg"
import trading1 from "@/assets/img/ueex/about-us/trading1.svg"
import trading2 from "@/assets/img/ueex/about-us/trading2.svg"
import trading3 from "@/assets/img/ueex/about-us/trading3.svg"
import trading4 from "@/assets/img/ueex/about-us/trading4.svg"
export default {
  name: "AboutUs",
  mixins: [commonMixin],
  data() {
    return {
      ourServicesItem: [
        {url: our1, title: "Buy & Sell crypto"},
        {url: our2, title: "Spot trading"},
        {url: our3, title: "Perpetual futures trading"},
        {url: our4, title: "US & HKex listed stocks"},
        {url: our5, title: "Crypto loans"},
      ],
      usefulToolsItem: [
        {url: trading1, title: "Demo trading"},
        {url: trading2, title: "Copy trading"},
        {url: trading3, title: "Auto invest"},
        {url: trading4, title: "Group chat"},
      ],
      milestonesTopItem: [
        {title:"Dec, 2020",text:"UEEx established"},
        {title:"Feb, 2022",text:"Total Users’ Assets exceeded $1 billion USD"},
        {title:"Jul, 2023",text:"Registered users exceeded 500,000"},
        {title:"Dec, 2023",text:"Daily trading value exceeded $300 million USD"},
        {title:"Jul, 2024",text:"Launch of UEEx Coin Miner App on Telegram"},
        {title:"Jul, 2024",text:"UEEx IEO & Use Case Deployment"},
      ],
      milestonesBtmItem: [
        {title:"Sep, 2021",text:"Registered users exceeded 100,000"},
        {title:"Jan, 2023",text:"Daily Trading Value Exceeded $100 Million USD"},
        {title:"Sep, 2023",text:"Total Users’ Assets exceeded $3 billion USD"},
        {title:"Jul, 2024",text:"Total Users’ Assets exceeded $5 billion USD"},
        {title:"Jul, 2024",text:"UE Coin listed"},
        {title:"Aug, 2024",text:"Registered Users Exceeded 1 Million"},
      ]

    };

  }

};
</script>

<style lang="scss">
.ueex-about-page {
  .about-content-one {
    background: url("../../assets/img/ueex/about-us/one-bg.svg") lightgray 50% / cover no-repeat;

    .content {
      width: 1132px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      min-height: 200px;
      .title {
        color: #18181b;
        text-align: center;
        font-feature-settings: "liga" off, "clig" off;
        font-family: "Plus Jakarta Sans";
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px; /* 100% */
        width: 100%;
      }
    }
  }
  .about-content-two {
    background: #fff;
    .content {
      width: 1132px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 484px;
      .title {
        color: var(--Neutral-900, #18181b);
        text-align: center;
        font-feature-settings: "liga" off, "clig" off;
        font-family: "Plus Jakarta Sans";
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 100% */
      }
      main {
        margin-top: 80px;
        display: flex;
        gap: 40px;
        justify-content: space-between;
        .left {
          display: flex;
          flex-direction: column;
          width: 546px;
          height: 216px;
          color: var(--Neutral-900, #18181B);
          text-align: justify;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          .item {
            margin-bottom: 20px;
          }
        }
        .right {
          img {
            width: 546px;
            height: 216px;
            flex-shrink: 0;
            border-radius: 8px;
          }
        }
      }
    }
  }
  .about-content-three {
    background: #F6F7F8;
    .content {
      width: 1132px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 80px;
      min-height: 764px;
      .box {
        .title {
          color: var(--Neutral-900, #18181B);
          text-align: center;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: "Plus Jakarta Sans";
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px; /* 100% */
        }
        .item-box {
          margin-top: 40px;
          display: flex;
          gap: 20px;
          justify-content: center;
          align-items: center;
          .item {
            width: 210px;
            height: 154px;
            flex-shrink: 0;
            border-radius: 8px;
            background: #FFF;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            .img {
              img {
                width: 40px;
                height: 40px;
                flex-shrink: 0;
              }
            }
            .item-title {
              color: var(--Neutral-900, #18181B);
              font-feature-settings: 'liga' off, 'clig' off;
              font-family: "Plus Jakarta Sans";
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 14px; /* 100% */
            }
          }
        }

      }
    }
  }
  .about-content-four {
    background: #FFF;
    .content {
      width: 1132px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 80px;
      min-height: 448px;
      .title {
        color: #18181B;
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Plus Jakarta Sans";
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 100% */
      }
      main {
        display: flex;
        justify-content: space-between;
        gap: 88px;
        .left {
          flex: 1;
          color: #18181B;
          text-align: justify;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: "Plus Jakarta Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px; /* 100% */
        }
        .right {
          display: flex;
          gap: 20px;
          img {
            width: 114px;
            height: 220px;
            flex-shrink: 0;
            border-radius: 8px;
          }
        }
      }
    }
  }
  .about-content-five {
    background: #F6F7F8;
    .content {
      width: 1132px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      min-height: 448px;
      .title-box {
        display: flex;
        justify-content: flex-end;
        width: 100%;
       .title {
        width: 516px;
        color: #18181B;
        text-align: start;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Plus Jakarta Sans";
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 100% */
        }
      }
      main {
        display: flex;
        justify-content: space-between;
        gap: 100px;
        margin-top: 40px;
        .right {
          flex: 1;
          display: flex;
          gap: 76px;
          img {
            width: 220px;
            height: 220px;
            flex-shrink: 0;
            border-radius: 8px;
          }
        }
        .left {
          width: 516px;
          height: 220px;

          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .item {
            color: #18181B;
            text-align: justify;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
            >div {
              display: flex;
              gap: 8px;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .about-content-six {
    background: #FFF;
    padding-top: 50px;
    .content {
      width: 1132px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      min-height: 582px;
      .title {
        color: var(--Neutral-900, #18181B);
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Plus Jakarta Sans";
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 100% */
        width: 100%;
      }
      main {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        .top {
          transform: translateY(16px);
          display: flex;
          justify-content: flex-start;
          gap: 150px;
          padding-left: 124px;
          .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 10px;
            .item-text-box {
              color: var(--Neutral-900, #18181B);
              text-align: center;
              font-feature-settings: 'liga' off, 'clig' off;
              font-family: "Plus Jakarta Sans";
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px; /* 100% */
              width: 120px;
              height: 120px;
              .item-text {
                margin-top: 12px;
              }
            }
            .item-line {
              width: 1px;
              height: 60px;
              background: url('../../assets/img/ueex/about-us/six-line.svg');
              background-size: 200% 100%;

            }
            .item-round {
            }
          }
        }
        .btm {
          transform: translateY(-15px);
          display: flex;
          justify-content: flex-end;
          gap: 150px;
          padding-right: 114px;
          .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 10px;
            .item-text-box {
              color: var(--Neutral-900, #18181B);
              text-align: center;
              font-feature-settings: 'liga' off, 'clig' off;
              font-family: "Plus Jakarta Sans";
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px; /* 100% */
              width: 120px;
              height: 120px;
              .item-text {
                margin-top: 12px;
              }
            }
            .item-line {
              width: 1px;
              height: 60px;
              background: url('../../assets/img/ueex/about-us/six-line.svg');
              background-size: 200% 100%;

            }
            .item-round {
            }
          }
        }
      }
    }
  }
}
</style>
