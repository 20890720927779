/*
 * @Author: your name
 * @Date: 2021-08-24 21:19:48
 * @LastEditTime: 2021-10-21 21:29:58
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /topcredit-exchange/src/main.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import i18n from "./i18n";
import store from "./store";
import router from "./router/index.js";
// import $ from 'jquery'
// window.$ = $
import echarts from "echarts";
import VueAwesomeSwiper from "vue-awesome-swiper";
import UniqueId from "vue-unique-id";
import VueClipboard from "vue-clipboard2";
import ElementUI from "element-ui";
import global from "@/assets/js/global.js";
import VueSocialSharing from "@/assets/social-sharing/vue-social-sharing";
import "@/3rd/element-ui.js";
import dinpro from "@/assets/din-pro/font.scss";
import pingfang from "@/assets/pingfang/font.scss";
import opensans from "@/assets/opensans/font.scss";
import "@/assets/css/reset.css";
import "@/assets/css/font.css";
import "@/assets/css/common.scss";
import "@/assets/iconfont/iconfont.css";
import Meta from "vue-meta";

// import '../node_modules/swiper/swiper.min.css'
import "../node_modules/swiper/swiper-bundle.css";
require("@/assets/js/gt4.js");
// 加载主题
const requireTheme = require.context(
  "./assets/styles/themes",
  false,
  /\.scss$/
);
requireTheme.keys().forEach(fileName => {
  requireTheme(fileName);
});

// Vue.prototype.resetSetItem = function (key, newVal) {
//   if (key === 'contract-multiple-num') {
//     var newStorageEvent = document.createEvent('StorageEvent');
//     const storage = {
//       setItem: function (k, val) {
//         localStorage.setItem(k, val);
//         newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
//         window.dispatchEvent(newStorageEvent)
//       }
//     }
//     return storage.setItem(key, newVal);
//   }
// }
Vue.prototype.global = global;
Vue.prototype.$echarts = echarts;
Vue.prototype.$initGeetest4 = initGeetest4;
Vue.use(VueRouter);
Vue.use(VueAwesomeSwiper);
Vue.use(UniqueId);
Vue.use(VueClipboard);
Vue.use(ElementUI);
Vue.use(require("vue-wechat-title"));
Vue.use(VueSocialSharing);
Vue.use(pingfang);
Vue.use(dinpro);
Vue.use(opensans);
Vue.use(Meta);
// Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  i18n,
  store,
  router,
}).$mount("#app");
