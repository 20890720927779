import VueRouter from "vue-router";
import auth from "./auth.js";
import Exchange from "@/pages/exchange/index.vue";
import Markets from "@/pages/markets/markets.vue";
import Assets from "@/pages/ucenter/assets.vue";
import Letter from "@/pages/insideLetter/letter.vue";
import Quicklycoins from "@/pages/quicklycoins/quicklycoins.vue";
// import Withdrawal from "@/pages/assets/recharge_withdrawal.vue";
// import Recharge from "@/pages/assets/recharge_withdrawal.vue";
import Situation from "@/pages/ucenter/situation.vue";
import Security from "@/pages/login/security.vue";
// import Security from "@/pages/ucenter/security-setting.vue";
import Orderbook from "@/pages/exchange/orderbook.vue";
import ContractExchange from "@/pages/contract-exchange/contract-exchange.vue";
import ContractOrderbook from "@/pages/contract-exchange/contract-orderbook.vue";
import StockExchange from "@/pages/stock-exchange/stock-exchange.vue";
import Home from "@/pages/home/home.vue";
import AboutUs from "@/pages/about-us/index.vue";
import Careers from "@/pages/careers/index.vue";
import KycNotice from "@/pages/notice/kyc-notice.vue";
import FormerUniverse from "@/pages/formerUniverse/formerUniverse";
import Finances from "@/pages/finances/index";
import MiningPool from "@/pages/mining-pool/index.vue";
import Delivery from "@/pages/currency-based-contract/currency-based-contract.vue";
import DeliveryOrderbook from "@/pages/currency-based-contract/currency-based-contract-orderbook.vue";
import ServiceAgreement from "@/pages/service-page/service-agreement.vue";
import PrivacyPolicy from "@/pages/service-page/privacy-policy.vue";
import AssetActivity from "@/pages/assetactivity/index.vue";

import MarketsNew from "@/pages/markets_new/markets_new.vue";
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";
// import index from "@/pages/ucenter/index.vue"
// import Login from '@/pages/login/index'

// 用户中心相关路由
import ucenter from "../pages/ucenter/routers";
// import ucenter  from "../pages/ucenter/router/index"
const routes = [
  ucenter,
  {
    path: "/login/:lang?",
    name: "Login",
    meta: {
      title: "股票交易所 | 加密货币交易平台 | UEEx",
      description: "登录UEEx以安全管理您的数字资产，交易加密货币，并访问创新的金融工具。享受无缝、安全的登录，并在值得信赖的UEEx平台上开始交易。"
    },
    component: () => import("@/pages/login/index"),
  },
  {
    path: "/landingpage/register/:lang?",
    name: "InviteRegister",
    meta: {
      title: "股票交易所 | 加密货币交易平台 | UEEx",
      description: "登录UEEx以安全管理您的数字资产，交易加密货币，并访问创新的金融工具。享受无缝、安全的登录，并在值得信赖的UEEx平台上开始交易。"
    },
    component: () => import("@/pages/login/invite-register-maskex"),
  },

  {
    path: "/forgetPwd/:lang?",
    name: "ForgetPwd",
    meta: {
      title: "忘记密码",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    // component: Login,
    component: () => import("@/pages/login/forgetPwd"),
  },
  {
    path: "/register/:lang?",
    name: "Register",
    meta: {
      title: "股票交易所 | 加密货币交易平台 | UEEx",
      description: "加入UEEx，领先的股票和加密货币交易平台。立即注册，享受安全交易、先进的交易工具和无缝的股票和数字资产市场访问。"
    },
    // component: Login,
    component: () => import("@/pages/login/register"),
  },
  {
    path: "/login/mobile/:lang?",
    name: "LoginMobile",
    meta: {
      title: "股票交易所 | 加密货币交易平台 | UEEx",
      description: "登录UEEx以安全管理您的数字资产，交易加密货币，并访问创新的金融工具。享受无缝、安全的登录，并在值得信赖的UEEx平台上开始交易。"
    },
    // component: Login,
    component: () => import("@/pages/login/loginVerification"),
  },
  {
    path: "/login/email/:lang?",
    name: "LoginEmail",
    meta: {
      title: "邮箱登陆",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    // component: Login,
    component: () => import("@/pages/login/loginVerification"),
  },
  {
    path: "/login/verify/:lang?",
    name: "LoginVerify",
    meta: {
      title: "绑定验证",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: () => import("@/pages/login/loginFirstBind"),
  },
  //币币交易
  {
    path: "/exchange",
    meta: {
      title: "在UEEx交易BTC、ETH和山寨币 – 安全快捷的加密货币交易所",
      description: "在可靠的平台UEEx上安全交易BTC和表情包币，享受深度流动性和具有竞争力的数字资产交易利率。立即开始交易！",

    },
    component: Exchange,
  },
  {
    path: "/exchange/:symbol/:lang?",
    meta: {
      title: "在UEEx交易BTC、ETH和山寨币 – 安全快捷的加密货币交易所",
      description: "在可靠的平台UEEx上安全交易BTC和表情包币，享受深度流动性和具有竞争力的数字资产交易利率。立即开始交易！",
    },
    component: Exchange,
  },
  //合约交易
  {
    path: "/contract-exchange",
    meta: {
      title: "U本位合约",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: ContractExchange,
  },
  {
    path: "/contract-exchange/:symbol/:lang?",
    meta: {
      title: "U本位合约",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: ContractExchange,
  },
  //币本位合约交易
  {
    path: "/delivery",
    meta: {
      title: "币本位合约",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: Delivery,
  },
  {
    path: "/delivery/:symbol/:lang?",
    meta: {
      title: "币本位合约",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: Delivery,
  },
  {
    path: "/delivery-orderbook/:lang?",
    meta: {
      title: "币本位合约盘口",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: DeliveryOrderbook,
  },
  //股票交易
  {
    path: "/stock-exchange/:lang?",
    meta: {
      title: "股票交易",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: StockExchange,
  },
  {
    path: "/stock-exchange/:symbol/:lang?",
    meta: {
      title: "股票交易",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: StockExchange,
  },
  {
    path: "/orderbook/:lang?",
    meta: {
      title: "币币盘口",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: Orderbook,
  },
  {
    path: "/contract-orderbook/:lang?",
    meta: {
      title: "合约盘口",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: ContractOrderbook,
  },
  //快捷买币
  {
    path: "/quicklycoins/:lang?",
    meta: {
      title: "快捷买币",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: Quicklycoins,
  },

  //行情
  {
    path: "/markets/:lang?",
    meta: {
      title: "在UEEx上管理您的数字资产财富 – 安全的加密货币解决方案",
      description: "使用UEEx控制您的加密货币财务。享受安全高效的管理工具来支持产生收益的策略，确保数字资产的无缝体验。"
    },
    component: Markets,
  },

  //行情新版
  {
    path: "/markets-new/:lang?",
    meta: {
      title: "在UEEx上管理您的数字资产财富 – 安全的加密货币解决方案",
      description: "使用UEEx控制您的加密货币财务。享受安全高效的管理工具来支持产生收益的策略，确保数字资产的无缝体验。"
    },
    component: MarketsNew,
  },
  //资产管理
  {
    path: "/ucenter/assets/:lang?",
    meta: {
      title: "资产管理",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: Assets,
  },

  //站内信
  {
    path: "/letter/:lang?",
    meta: {
      title: "站内信",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: Letter,
  },

  {
    name: "Security",
    path: "/loginSecurity/:lang?",
    meta: {
      title: "安全项丢失",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: Security,
  },
  {
    name: "Finances",
    path: "/finances/:lang?",
    meta: {
      title: "金融理财",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: Finances,
  },



  {
    name: "KycNotice",
    path: "/kyc-notice/:lang?",
    meta: {
      title: "KYC-notice",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: KycNotice,
  },
  {
    name: "Metaverse",
    path: "/metaverse/:lang?",
    meta: {
      title: "元宇宙",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: FormerUniverse,
  },

  {
    name: "MiningPool",
    path: "/mining-pool/:lang?",
    meta: {
      title: "汇信池",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: MiningPool,
  },
  {
    name: "ServiceAgreement",
    path: "/service-agreement/:lang?",
    meta: {
      title: "服务协议",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: ServiceAgreement,
  },
  {
    name: "PrivacyPolicy",
    path: "/privacy-policy/:lang?",
    meta: {
      title: "隐私条款",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: PrivacyPolicy,
  },
  {
    name: "/AboutUs",
    path: "/about-us/:lang?",
    meta: {
      title: "关于我们",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: AboutUs,
  },
  {
    name: "/VoteForListing",
    path: "/vote-for-listing/:lang?",
    meta: {
      title: "Voting",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: AssetActivity,
  },
  {
    name: "/Careers",
    path: "/careers/:lang?",
    meta: {
      title: "Careers",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: Careers,
  },

  //停用404
  // {
  //   redirect: "/404",
  //   path: "*",
  //   component: ErrorPage,
  //   meta: {
  //     title: "404",
  //   },
  //   children: [
  //     {
  //       path: "/404",
  //       meta: {
  //         title: "404",
  //       },
  //       component: ErrorPage,
  //     },
  //   ],
  // },
  // {
  //   redirect: "/",
  //   path: "*",
  // },

  {
    path: "/",
    meta: {
      title: "UEEx: 最佳加密货币和股票交易平台",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: Home,
  },

  {
    path: "/:lang?",
    meta: {
      title: "UEEx: 最佳加密货币和股票交易平台",
      description: "UEEx 是最佳加密货币和股票交易平台，使交易更加轻松。进行加密货币现货和衍生品交易。"
    },
    component: Home,
  },
  {
    path: "*",
    redirect: "/",
  },
];

// let langSuffix = localStorage.getItem("language") == "en"
//   ? ["8V"].includes(VUE_UNION_STATION)
//     ? ""
//     : localStorage.getItem("language") ||
//       (["MaskEx", "S6", "B9", "bitda"].includes(VUE_UNION_STATION)
//         ? "en"
//         : "zh_CN")
//   : localStorage.getItem("language") ||
//     (["MaskEx", "S6", "B9", "bitda"].includes(VUE_UNION_STATION)
//       ? "en"
//       : "zh_CN");
const router = new VueRouter({
  mode: "history",
  routes,
});
auth(router);

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
};

export default router;
