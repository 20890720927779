<template>
  <div class="page-header">
    <div class="left-pages">
      <a :href="homeUrl">
        <img :draggable="false" class="logo" :class="isMaskEx ? 'isMaskEx' : ''" :src="logImg" alt />
      </a>
      <ul class="pages-routes">
        <a :href="routeSplicing(language, `markets`)">
          <li :class="active == 1 ? 'active' : ''">
            <a :href="routeSplicing(language, `markets`)">{{
              translateTitle("行情")
            }}</a>
          </li>
        </a>

        <a :href="routeSplicing(language, 'exchange/btc_usdt')">
          <li :class="active == 2 ? 'active' : ''">
            <a :href="routeSplicing(language, 'exchange/btc_usdt')">{{
              translateTitle("币币交易")
            }}</a>
          </li>
        </a>
        <!-- :href="routeSplicing(language, `contract-exchange/btcusdt`)" -->
        <a v-if="!isHideLever" @click="onDownloadTipsDialog">
          <li :class="active == 3 ? 'active' : ''">
            <a>{{ translateTitle("U本位合约") }}</a>
          </li>
        </a>
        <!-- <a
          :href="routeSplicing(language, `delivery/ethusd`)"
          v-if="!isHideLever && WEB_OPEN_COINM == 1"
        >
          <li :class="active == 6 ? 'active' : ''">
            <a :href="routeSplicing(language, `delivery/ethusd`)">{{
              translateTitle("币本位合约")
            }}</a>
          </li>
        </a> -->
        <a :href="routeSplicing(language, `finances`)">
          <li :class="active == 4 ? 'active' : ''">
            <a :href="routeSplicing(language, `finances`)">{{
              translateTitle("金融理财")
            }}</a>
          </li>
        </a>
        <a :href="routeSplicing(language, `vote-for-listing`)">
          <li :class="active == 5 ? 'active' : ''">
            <a :href="routeSplicing(language, `vote-for-listing`)">{{
              translateTitle("Voting")
            }}</a>
          </li>
        </a>
      </ul>
    </div>
    <div class="right-menus">
      <span class="station-name" v-if="app_env == 'test' || app_env == undefined">
        {{ envName }}
      </span>
      <span class="customer-service" v-if="isUEEx && userInfo" @click="onCustomerServiceClick">
        {{ translateTitle("咨询客服") }}
      </span>

      <el-menu class="right-menu" mode="horizontal" @select="handleSelect">
        <template>
          <!-- 个人中心 -->
          <user-menu></user-menu>
        </template>
        <!-- <template>
            <a href="/letter">
              <li class="letter-center">
                <el-badge class="item" is-dot :hidden="true">
                  <img src="../../assets/img/letter.png" alt="" />
                </el-badge>
                <div class="driver"></div>
              </li>
            </a>
          </template> -->
        <template>
          <!-- 注册 -->
          <el-menu-item class="nav-menu-item login" v-show="!userInfoShow">
            <router-link active-class="none-active" :to="routeSplicing(language, 'login')">{{ translateTitle("登录")
              }}</router-link>
          </el-menu-item>
          <!-- 注册 -->
          <el-menu-item class="nav-menu-item register theme-btn" v-show="!userInfoShow">
            <router-link active-class="none-active" :to="routeSplicing(language, 'register')">{{ translateTitle("注册")
              }}</router-link>
            <!-- <div class="driver"></div> -->
          </el-menu-item>
        </template>
        <template>
          <li class="vertical-line-li">
            <span class="vertical-line"></span>
          </li>
        </template>
        <template>
          <li class="download-app" @mouseenter="onShowDownLoad" @mouseleave="onHiddenDownLoad">
            <span class="driver"></span>
            <span class="download-app-text">
              <i class="iconfont iconicon-hd-3"></i>
              {{ translateTitle("APP下载") }}</span>
            <div v-show="showDownLoadImg" class="down-box">
              <div>
                <p class="down-load-text">
                  {{ translateTitle("扫码下载iOS&Android APP") }}
                </p>
                <span class="img-content">
                  <p class="left-item">
                    <span class="down-load-item-text">
                      <img class="icon-title" draggable="false" src="../../assets/img/apple.png" alt="" />
                      iPhone
                    </span>
                    <span class="down-load-item-text V2">
                      <img class="icon-title" draggable="false" src="../../assets/img/andriod.png" alt="" />
                      Android
                    </span>
                  </p>
                  <img class="down-load-url" :src="appDownLoadImg" alt="" />
                </span>
              </div>
            </div>
          </li>
        </template>

        <el-submenu class="select-fiat no-arrow" popper-class="sub-item-wrap  select-fiat-currency" index="6"
          :collapse-transition="false" :popper-append-to-body="false">
          <template slot="title" class="lang">
            <router-link class="lang" to="#">
              {{ currency }}
            </router-link>
          </template>
          <el-menu-item class="sub-item" v-for="(item, index) in currencyMaps" :key="index" :index="item.logic_name"
            @click="onCurrencyChange(item.logic_name)">
            <span>
              <img :src="item.icon" />
              {{ item.abbreviate }}
            </span>
          </el-menu-item>
        </el-submenu>
        <!-- 语言 -->
        <li class="el-submenu sub-item-wrap select-lang-ueex" @click="onMaskLangDialog">
          <img :src="imgUrl" />
          <span>
            {{ languageName }}
          </span>
        </li>
      </el-menu>
    </div>

    <el-dialog custom-class="langVisible" :visible.sync="langVisible">
      <ul>
        <li v-for="item in countryList" :key="item.value" @click="onLangClick(item)">
          <img :src="item.img" alt="" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </el-dialog>
    <el-dialog custom-class="downloadTipsVisible" :visible.sync="downloadTipsVisible" :show-close="true"
      :before-close="closeDownloadTipsDialog">
      <p class="t">{{ translateTitle("APP下载提示") }}</p>
      <p class="c">
        {{
          translateTitle("您可下载") +
          "APP " +
          translateTitle("使用该功能业务,该WEB功能正在开发中，敬请期待！")
        }}
      </p>
      <div class="d">
        <div class="b">
          <a target="_blank">
            <img src="../../assets/img/Apple-store-2.png" class="as" />
          </a>
          <a target="_blank">
            <img src="../../assets/img/apk-day.png" class="apk" />
          </a>
        </div>
        <div class="q">
          <img :src="appDownLoadImg" class="code" />
          <span class="qt">{{
            translateTitle("扫码下载iOS&Android APP")
          }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import commonMixin from "@/components/common/commonMixin.vue";
import menuControlMixin from "@/components/common/menuControlMixin.vue";
import QrcodeVue from "qrcode.vue";
import UserMenu from "./UserMenu";
import { apiGetStaticTableInfo } from "@/model/settings";
// const { VUE_APP_ID, VUE_APP_APP } = process.env;
import zhCN from "../../assets/img/lang/f-cn.png";
import zhHK from "../../assets/img/lang/f-hk.png";
import enUS from "../../assets/img/lang/f-en.png";
import Ja from "../../assets/img/lang/f-ja.png";
import Ko from "../../assets/img/lang/f-ko.png";

import ar from "../../assets/img/lang/f-ar.png";
import fr from "../../assets/img/lang/f-fr.png";
import es from "../../assets/img/lang/f-es.png";
import ru from "../../assets/img/lang/f-ru.png";
import de from "../../assets/img/lang/f-de.png";
import pt from "../../assets/img/lang/f-pt.png";
import hi from "../../assets/img/lang/f-hi.png";
import vi from "../../assets/img/lang/f-vi.png";
import tl from "../../assets/img/lang/f-tl.png";
import th from "../../assets/img/lang/f-th.png";
import tr from "../../assets/img/lang/f-tr.png";
import it from "../../assets/img/lang/f-it.png";
import id from "../../assets/img/lang/f-id.png";
import {
  VUE_APP_ID,
  VUE_CHANNEL_ID,
  VUE_UNION_STATION,
  VUE_APP_ENV,
  WEB_OPEN_COINM,
} from "@/assets/js/stationSetting";
import { getLangPackage } from "@/assets/js/lang";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin, menuControlMixin],
  components: {
    UserMenu,
    // QrcodeVue,
  },
  data() {
    return {
      WEB_OPEN_COINM,
      homeUrl: "",
      app_env: "",
      languageName: "English",
      currency: "CNY",
      appDownLoadImg: "",
      appstore_url: "",
      tipUrl: "",
      google_play_url: "",
      langVisible: false,
      showDownLoadImg: false,
      isMaskEx: false,
      is8V: false,
      isbitda: false,
      isUEEx: false,
      isAhex: false,
      is8Coin: false,
      downloadTipsVisible: false,
      isB9: false,
      currencyMaps: {},
      logImg: require("../../assets/img/logo.png"),
      imgUrl: require("../../assets/img/en.png"),
      countryList: [
        {
          name: "English",
          value: "en",
          img: enUS,
        },
        {
          name: "日本語",
          value: "ja",
          img: Ja,
        },
        {
          name: "한국인",
          value: "ko",
          img: Ko,
        },

        {
          name: "Français",
          value: "fr",
          img: fr,
        },
        {
          name: "Español",
          value: "es",
          img: es,
        },
        {
          name: "Pусский",
          value: "ru",
          img: ru,
        },
        {
          name: "Deutsch",
          value: "de",
          img: de,
        },
        {
          name: "Português",
          value: "pt",
          img: pt,
        },
        {
          name: "हिंदी",
          value: "hi",
          img: hi,
        },
        {
          name: "Tiếng Việt",
          value: "vi",
          img: vi,
        },
        {
          name: "Pilipino",
          value: "tl",
          img: tl,
        },
        {
          name: "ภาษาไทย",
          value: "th",
          img: th,
        },
        {
          name: "Türk",
          value: "tr",
          img: tr,
        },
        {
          name: "Italiano",
          value: "it",
          img: it,
        },
        {
          name: "bahasa Indonesia",
          value: "id",
          img: id,
        },
        {
          name: "عربي",
          value: "ar",
          img: ar,
        },
        {
          name: "中文简体",
          value: "zh_CN",
          img: zhCN,
        },
        {
          name: "中文繁體",
          value: "zh_HK",
          img: zhHK,
        },
      ],
    };
  },
  created() {
    this.homeUrl = `/`;
    this.app_env = VUE_APP_ENV;
    // this.getCurrencyMap();
    this.isUEEx = true;
    this.logImg = require("../../assets/img/logo_UEEx.png");

    let tempUrl = JSON.parse(sessionStorage.getItem("qrcode_download"));
    this.appDownLoadImg = tempUrl.url;
    this.appstore_url = tempUrl.appstore_url;
    this.google_play_url = tempUrl.google_play_url;

    let currency = localStorage.getItem("currency") || "CNY";
    let tempLang = localStorage.getItem("language") || "en";
    if (tempLang) {
      this.countryList.forEach((ele) => {
        if (ele.value == tempLang) {
          this.imgUrl = require("../../assets/img/lang/f-" +
            ele.value +
            ".png");
          this.languageName = ele.name;
        }
      });
    } else {
      tempLang = "en";
      this.countryList.forEach((ele) => {
        if (ele.value == tempLang) {
          this.imgUrl = require("../../assets/img/lang/f-" +
            ele.value +
            ".png");
          this.languageName = ele.name;
        }
      });
    }

    let zendesk_notice = JSON.parse(sessionStorage.getItem("zendesk_notice"));

    if (zendesk_notice.language) {
      this.tipUrl = zendesk_notice.language[`notice_url_${this.language}`];
    } else {
      this.tipUrl = zendesk_notice.notice_url;
    }

    this.handleSelect(currency);
  },
  mounted() {
    // let tempList = JSON.parse(localStorage.getItem("settingList"));
    // if (tempList) {
    //   this.currencyMaps = tempList[7];
    // }
    this.getStaticChainInfo();
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      environment: "user/environment",
      darkMode: "user/darkMode",
    }),
    envName() {
      let tempVal = localStorage.getItem("environment");
      return tempVal;
    },
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
    active() {
      let val = 0;
      if (this.$route.path.includes("/markets")) {
        val = 1;
      } else if (this.$route.path.includes("/exchange")) {
        val = 2;
      } else if (this.$route.path.includes("/contract-exchange")) {
        val = 3;
      } else if (this.$route.path.includes("/finances")) {
        val = 4;
      } else if (this.$route.path.includes("/mining-pool")) {
        val = 5;
      } else if (this.$route.path.includes("/delivery")) {
        val = 6;
      } else if (this.$route.path.includes("/quicklycoins")) {
        val = 7;
      } else {
        val = 0;
      }
      return val;
    },
  },
  methods: {
    routeSplicing,
    ...mapActions({
      changeLanguage: "user/changeLanguage",
      changeCurrency: "user/changeCurrency",
      changeDarkMode: "user/changeDarkMode",
    }),
    // 新菜单结束
    onDownloadTipsDialog() {
      this.downloadTipsVisible = true;
    },
    closeDownloadTipsDialog() {
      this.downloadTipsVisible = false;
    },

    async getStaticChainInfo() {
      await apiGetStaticTableInfo(7).then(({ data }) => {
        this.currencyMaps = data;
      });
    },
    onChangeStyle() {
      if (this.darkMode == 1) {
        this.changeDarkMode(2);
      } else {
        this.changeDarkMode(1);
      }
    },
    async getCurrencyMap() {
      const { status, data } = await apiGetStaticTableInfo(7);
      if (status == 200) {
        this.currencyMaps = JSON.parse(JSON.stringify(data));
      }
    },
    goQuicklycoins() {
      if (!this.userInfo) {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
        });
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "quicklycoins"),
        });
      }
    },
    onCustomerServiceClick() {
      this.$emit("onApiClick");
    },
    onCurrencyChange(key) {
      this.currency = key.toUpperCase();
      this.changeCurrency(key);
    },
    handleSelect(key) {
      let command = "zh_CN";
      if (key == "zh_CN") {
        command = "zh_CN";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "zh_HK") {
        command = "zh_HK";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "en") {
        command = "en";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "ja") {
        command = "ja";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "ko") {
        command = "ko";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "cny") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "hkd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "aed") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "jpy") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "krw") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "usd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "try") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "mmk") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "php") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "sgd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "thb") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "vnd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "brl") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      }
    },
    onMaskLangDialog() {
      this.langVisible = true;
    },
    onLangClick(item) {
      this.imgUrl = require("../../assets/img/lang/f-" + item.value + ".png");
      // getLangPackage([item.value]);
      const langPackage = getLangPackage([item.value]);
      this.languageName = item.name;
      this.$i18n.setLocaleMessage(item.value, langPackage[item.value]);
      this.changeLanguage(item.value);
      this.$i18n.locale = item.value;
      this.langVisible = false;

      window.location.href = `${window.location.protocol}//${window.location.host}/${this.language}`;
      // window.location.href = `${window.location.protocol}//${window.location.host}/${this.language}`;
    },
    onShowDownLoad() {
      this.showDownLoadImg = true;
    },
    onHiddenDownLoad() {
      this.showDownLoadImg = false;
    },
  },
};
</script>
<style lang="scss">
.zh_CN {
  .page-header {
    .right-menus .el-menu .download-app {
      width: 120px;
    }

    .page-header .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}

.zh_HK {
  .page-header {
    .right-menus .el-menu .download-app {
      width: 120px;
    }

    .page-header .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}

.hi {
  .page-header {
    .right-menus .el-menu .download-app {
      width: 145px;
    }

    .page-header .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}

.it {
  .page-header {
    .right-menus .el-menu .download-app {
      width: 183px;
    }

    .page-header .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}

.de {
  .page-header {
    .right-menus .el-menu .download-app {
      width: 182px;
    }

    .page-header .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}

.vi {
  .page-header {
    .right-menus .el-menu .download-app {
      width: 193px;
    }

    .page-header .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}

.tr {
  .page-header {
    .right-menus .el-menu .download-app {
      width: 141px;
    }

    .page-header .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}

.tl {
  .page-header {
    .right-menus .el-menu .download-app {
      width: 162px;
    }

    .page-header .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}

.ru {
  .page-header {
    .right-menus .el-menu .download-app {
      width: 162px;
    }

    .page-header .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}

.th {
  .page-header {
    .right-menus .el-menu .download-app {
      width: 162px;
    }

    .page-header .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}

.pt {
  .page-header {
    .right-menus .el-menu .download-app {
      width: 162px;
    }

    .page-header .right-menus .el-menu .download-app .down-box {
      width: 265px;
    }
  }
}

.en {
  .page-header {
    min-width: 1468px;

    .right-menus .el-menu .download-app {
      width: 170px;
    }
  }

  .page-header .right-menus .el-menu .download-app .down-box {
    width: 290px;
  }

  .page-header .right-menus .el-menu .el-submenu .el-menu--horizontal .el-menu {
    width: 195px !important;
  }
}

.fr {
  .page-header {
    .right-menus .el-menu .download-app {
      width: 221px;
    }
  }
}

.es {
  .page-header {
    .right-menus .el-menu .download-app {
      width: 247px;
    }
  }
}

.ko {
  .page-header {
    .right-menus .el-menu .download-app {
      width: 144px;
    }
  }
}

.ja {
  .page-header {
    .right-menus .el-menu .download-app {
      width: 202px;
    }
  }

  .page-header .right-menus .el-menu .download-app .down-box {
    width: 280px;
    height: 166px;
  }

  .page-header .right-menus .el-menu .el-submenu .el-menu--horizontal .el-menu {
    width: 180px !important;
  }
}

.ar {
  .page-header {
    direction: ltr !important;
  }

  .page-header {
    .right-menus .el-menu .download-app {
      width: 130px;
    }
  }

  .user-center-menu.el-menu-item.nav-menu-item.login .el-menu--horizontal.sub-item-wrap.select-user-item .el-menu .el-menu-item {
    text-align: right;
  }
}
</style>
<style lang="scss">
/* yuan language innerBody */
//修改Lang宽度
.select-lang-ueex {
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  color: #18181b;
  font-weight: 600;

  &:hover {
    opacity: 1;
  }

  img {
    vertical-align: middle;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }

  span {
    font-size: 14px;
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}

.el-menu--horizontal.sub-item-wrap.select-lang {
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }

  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }

  &.V2 {
    margin-right: 20px;
  }

  top: 59px !important;

  &.merchant-item {
    width: 135px;
  }

  //  修改下拉菜单
  .el-menu {
    background-color: #fff;
    width: 130px !important;
    min-width: 130px !important;

    .el-menu-item {
      height: 40px;
      line-height: 40px;
      min-width: auto;
      border-radius: 4px;

      span {
        margin-left: 0px;

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }
}

//修改货币宽度
.el-menu--horizontal.sub-item-wrap.select-fiat-currency {
  &.merchant-item {
    width: 135px;
  }

  //  修改下拉菜单
  .el-menu {
    background-color: #fff;

    // width: 130px !important;
    // min-width: 130px !important;
    .el-menu-item {
      height: 40px;
      line-height: 40px;
      min-width: auto;
      border-radius: 4px;

      &:hover {
        background-color: #f3f7fc;
      }

      span {
        margin-left: 0px;

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }
}

.select-fiat-currency {
  top: 59px !important;

  &.el-menu--horizontal {
    width: 140px !important;
  }

  .el-menu {
    width: 140px !important;
  }

  margin-right: 20px;
  width: 140px !important;

  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }

  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
}

/* yuan language innerBody */
.page-header {
  user-select: none;
  // overflow: hidden;
  // height: 100%;
  height: 60px;
  background-color: #12161c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .logo {
    width: 110px;
    height: 32px;
  }

  .langVisible {
    z-index: 999;

    ul {
      min-width: 700px;
      margin-left: 20px;
    }

    li {
      cursor: pointer;
      display: inline-block;
      width: 25%;
      padding: 10px 10px;

      &:hover {
        color: black;
      }

      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
      }

      span {
        vertical-align: middle;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
    margin-top: 0;
  }

  .left-pages,
  .right-menus,
  .el-submenu__title {
    display: flex;
    align-items: center;
    height: 100%;
  }

  // 左侧
  .left-pages {
    margin-left: 20px;
    display: flex;
    align-items: center;

    .pages-routes {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-left: 45px;

      li {
        color: #fff;
        height: 60px;
        font-size: 14px;
        line-height: 60px;
        margin-right: 20px;
        box-sizing: border-box;
        cursor: pointer;
        color: #acb9db;

        a {
          color: #18181b;
          text-decoration: none;
          display: inline-block;
          height: 100%;
          font-family: "Plus Jakarta Sans";
          font-size: 14px;
          font-weight: 600;
        }

        &.none-active {
          width: 100%;
          border-bottom: 3px solid #c61b3d;
          color: #fff;
          font-weight: 500;
        }

        &.active {
          border-bottom: 3px solid #c61b3d;
          color: #fff;
        }
      }

      .normal-link {
        padding: 0px;

        a {
          padding: 0 20px;
        }
      }
    }
  }

  .app-link {
    font-size: 14px;
  }

  // 右侧
  .right-menus {
    margin-right: 23px;
    height: 60px;

    .vertical-line-li {
      position: relative;
      float: left;

      .vertical-line {
        height: 22px;
        width: 1px;
        margin-left: 5px;
        background-color: rgba(255, 255, 255);
        display: inline-block;
        margin-top: 20px;
      }
    }

    .customer-service {
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
      cursor: pointer;

      &:hover {
        color: #ffffff;
      }
    }

    .right-menu {
      background-color: rgba(0, 0, 0, 0) !important;
    }

    .station-name {
      color: red;
      font-size: 24px;
      margin-right: 10px;
    }

    .el-submenu__icon-arrow {
      font-size: 16px;
      color: #acb9db;
    }

    .el-menu {
      background-color: #fff;
      text-align: left;

      // .channel-header-wrap {
      //   width: 156px !important;
      // }
      // .salary-header-warp {
      //   width: 156px !important;
      // }
      .letter-center {
        float: left;
        width: 40px;
        line-height: 22px;
        margin-top: 20px;
        position: relative;

        img {
          width: 22px;
          height: 22px;
          top: 0px;
          cursor: pointer;
        }

        .driver {
          position: absolute;
          background-color: rgb(172, 185, 219, 0.3);
          right: 0px;
          top: -2px;
          width: 1px;
          height: 30px;
        }
      }

      .download-app {
        // display: flex;
        float: left;
        position: relative;
        width: fit-content;
        height: 60px;
        outline: none;
        border-radius: 4px;
        // border-left: 1px solid #acb9db;

        .download-app-text {
          display: block;
          height: 100%;
          font-size: 14px;
          color: #18181b;
          line-height: 60px;
          padding: 0 20px;
          box-sizing: border-box;
          cursor: pointer;
          font-weight: 600;

          &:hover {
            color: #18181b;
            opacity: 1;
          }
        }

        .down-box {
          box-sizing: border-box;
          width: 265px;
          height: 150px;
          padding: 18px 30px 18px 19px;
          border-radius: 4px;
          box-shadow: 0 2px 10px 0 rgba(53, 64, 82, 0.1);
          background-color: #ffffff;
          display: block;
          position: relative;
          z-index: 3000;

          .down-load-text {
            color: #9aa1b1;
            font-size: 12px;
            font-weight: 500;

            .icon-title {
              width: 16px;
              height: 16px;
              vertical-align: text-top;
            }
          }

          .down-load-content {
            margin-top: 10px;
            display: flex;
            justify-content: space-around;
            text-align: center;

            .text-title {
              margin-bottom: 10px;
            }

            img {
              width: 70px;
              height: 70px;
            }
          }

          .img-content {
            display: flex;
            margin-top: 24px;

            .qr_code_canvas {
              margin-top: -10px;
            }

            .down-load-url {
              margin-top: -20px;
              height: 80px;
              width: 80px;
            }

            .down-load-item-text {
              display: inline-block;
              height: 20px;
              font-size: 14px;
              color: #354052;

              .icon-title {
                width: 19px;
                height: 21px;
                vertical-align: middle;
                margin-right: 10px;
              }

              &.V2 {
                margin-top: 17px;
              }
            }

            .down-load-img {
              width: 59px;
              height: 59px;
            }
          }
        }
      }

      .select-fiat {
        margin-right: 20px;
      }

      &.el-menu--horizontal {
        border-bottom: none;
      }

      .sub-item-child {
        color: red;

        #sub-item-asset & {
          &:hover {
            a {
              color: #000;
            }
          }
        }

        &.el-submenu {
          &:hover {
            a {
              color: #000;
            }
          }
        }

        .el-submenu__title {
          display: inline-block;
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          padding-left: 10px;
        }
      }

      .el-submenu {
        a {
          font-size: 14px;
          font-weight: 600;
          color: #18181b;
          line-height: 14px;
        }

        .el-menu--horizontal {
          &.merchant-item {
            width: 135px;
          }

          //  修改下拉菜单
          .el-menu {
            //  padding: 10px 0px;
            // width: 140px;
            background-color: #fff;

            .el-menu-item {
              height: 40px;
              line-height: 40px;

              // padding-left: 10px;
              // width: 140px;
              &:hover {
                background-color: #f3f7fc;
              }

              &.hide-icon {
                i {
                  visibility: hidden;
                }
              }

              i {
                margin-right: 10px;
                position: relative;
                top: -1px;
                color: inherit;
              }

              &.is-active {
                background-color: #fff !important;
                // span {
                //   color: #3a7dff !important;
                // }
              }
            }
          }
        }

        //修改Lang宽度
        .el-menu--horizontal.sub-item-wrap.select-lang {
          top: 59px !important;

          &.merchant-item {
            width: 135px;
          }

          //  修改下拉菜单
          .el-menu {
            background-color: #fff;
            width: 130px !important;
            min-width: 130px !important;

            .el-menu-item {
              height: 40px;
              line-height: 40px;
              min-width: auto;
              border-radius: 4px;

              span {
                margin-left: 0px;

                img {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  margin-right: 8px;
                }
              }
            }
          }
        }

        //修改货币宽度
        .el-menu--horizontal.sub-item-wrap.select-fiat-currency {
          &.merchant-item {
            width: 135px;
          }

          //  修改下拉菜单
          .el-menu {
            background-color: #fff;

            // width: 130px !important;
            // min-width: 130px !important;
            .el-menu-item {
              height: 40px;
              line-height: 40px;
              min-width: auto;
              border-radius: 4px;

              &:hover {
                background-color: #f3f7fc;
              }

              span {
                margin-left: 0px;

                img {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }

      .no-arrow {
        .el-submenu__icon-arrow {
          display: none;
        }
      }

      .el-submenu__title {
        border-bottom: 0;
        padding: 0;

        a {
          display: block;
          width: 100%;
          height: 100%;
          line-height: 60px;
          // margin-left: 20px;
          box-sizing: border-box;

          &.none-active {

            // width: 40px;
            &:after {
              content: "";
              height: 3px;
              width: 50px;
              background-color: #c61b3d;
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }

      .nav-menu-item {
        color: #fff;
        padding: 0;
        border-bottom: none;

        &.login {
          // margin: 0 10px;
          background-color: rgba(0, 0, 0, 0) !important;

          a {
            color: #18181b;
            font-weight: 600;
            text-align: center;
            background-color: rgba(0, 0, 0, 0);

            &.none-active {
              color: #fff;
              opacity: 1;
            }
          }
        }

        .driver {
          position: absolute;
          right: -40px;
          top: 0px;
          width: 1px;
          height: 30px;
          margin: 0 15px 0 22px;
          background-color: rgb(255, 255, 255, 0.5);
        }

        &.register {
          position: relative;
          background-color: #f28c34;
          height: 30px;
          margin-top: 8px;
          line-height: 30px;
          border-radius: 8px !important;
          border-right: 1px;
          border-right: 1px soild #acb9db;

          .none-active {
            border-radius: 8px;
          }

          a {
            line-height: 42px;
            color: white;
            border-radius: 8px;
            font-weight: 600;
            font-size: 16px;
            text-align: center;

            &.none-active {
              color: #fff;
              background-color: #c61b3d;
            }
          }
        }

        a {
          display: block;
          width: 90px;
          height: 42px;
          line-height: 58px;
          box-sizing: border-box;
          text-decoration: none;
          color: #acb9db;
        }
      }
    }

    .lang {
      img {
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 5px;
        border-radius: 10px;
        filter: brightness(200);
        -webkit-filter: brightness(150%);
      }
    }
  }

  .divider {
    width: 1px;
    height: 24px;
    background: rgba(216, 216, 216, 1);
    opacity: 0.18;
    position: relative;
    top: 20px;
  }

  .downloadTipsVisible {
    z-index: 999;
    width: 500px;
    margin-top: 30vh !important;
    min-height: 360px;
    padding: 30px 60px;
    display: flex;
    color: #112132;
    cursor: pointer;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
    }

    .t {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 30px;
    }

    .c {
      font-size: 14px;
      margin-bottom: 35px;
    }

    .d {
      display: flex;
      justify-content: space-between;
      height: 126px;
      width: 380px;

      .b {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        flex-shrink: 0;

        .as {
          width: 150px;
          height: 50px;
        }

        .apk {
          width: 150px;
          height: 50px;
        }
      }

      .q {
        margin-left: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        .code {
          width: 96px;
          height: 96px;
        }

        .qt {
          font-size: 14px;
          line-height: 1.43;
          word-break: break-word;
        }
      }
    }
  }
}

.el-submenu__title {
  background-color: rgba(0, 0, 0, 0) !important;
}

.select-lang {
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }

  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }

  &.V2 {
    margin-right: 20px;
  }
}

.select-fiat-currency {
  top: 59px !important;

  &.el-menu--horizontal {
    width: 140px !important;
  }

  .el-menu {
    width: 140px !important;
  }

  margin-right: 20px;
  width: 140px !important;

  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }

  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
}
</style>
